import { ORGANIZATION } from 'services/gpl/organization'

export const BRAND = `
    id
    brandId
    brandRelationship
    city
    country
    displayName
    ein
    email
    entityType
    identityStatus
    organization {
        ${ORGANIZATION}
    }
    phone
    postalCode
    state
    street
    vertical
    isMain
    companyName
    website
`
